import React from 'react'
import PropTypes from 'prop-types'
import AbstractComponent from 'app/base/AbstractComponent'
import advertPropTypes from 'app/model/advert/AdvertPropTypes'
import * as FormLines from '@inzeraty/form-lines'
import { Validation, FormError } from '@inzeraty/helpers'
import { UserEntity } from '@inzeraty/models'
import { Button } from '@sznds/react'
import { DefaultProps as DEFAULT_PROPS } from '@inzeraty/helpers'
import SellerCard from 'app/component/sellerCard/SellerCard'

import './DemandFormView.less'
import './DemandFormCS.json'
import 'app/base/BaseCS.json'

const { SZNDS_WIDGET_BY_ID, Form } = FormLines

const CLASSNAME = 'c-demand'
const FORM_IDS = {
	NAME: 'user_name',
	EMAIL: 'user_email',
	PHONE: 'user_phone',
	MESSAGE: 'message'
}

export default class DemandFormView extends AbstractComponent {
	static get propTypes() {
		return {
			className: PropTypes.string,
			defaultMessage: PropTypes.string,
			onSubmit: PropTypes.func.isRequired,
			onError: PropTypes.func.isRequired,
			onSuccess: PropTypes.func.isRequired,
			advertEntity: PropTypes.shape(advertPropTypes).isRequired,
			userEntity: PropTypes.instanceOf(UserEntity),
			getLocationOnPage: PropTypes.func
		}
	}

	static get defaultProps() {
		return {
			className: '',
			getLocationOnPage: DEFAULT_PROPS.FUNCTION
		}
	}

	constructor(props, context) {
		super(props, context)

		this.state = {
			formLineEntities: this._getInitFormLineEntities(props),
			isSending: false
		}

		this._dictionary = context.$Utils.$Dictionary

		this.renderWidgets = this.renderWidgets.bind(this)
		this.renderSubmitButton = this.renderSubmitButton.bind(this)
		this.validate = this.validate.bind(this)
		this.onChange = this.onChange.bind(this)
		this.onBlur = this.onBlur.bind(this)
		this.onSubmit = this.onSubmit.bind(this)
		this.onSubmitSuccess = this.onSubmitSuccess.bind(this)
		this.onSubmitError = this.onSubmitError.bind(this)
	}

	_getInitFormLineEntities({ userEntity = {} }) {
		const { login, name, phones = [] } = userEntity
		const [phoneData = {}] = phones
		const { phone } = phoneData

		return FormLines.createEntityList([
			{
				widget: FormLines.IDS.TEXT,
				id: FORM_IDS.NAME,
				label: this.localize('DemandForm.nameLabel'),
				value: name,
				required: true
			},
			{
				widget: FormLines.IDS.PHONE,
				id: FORM_IDS.PHONE,
				label: this.localize('DemandForm.phoneLabel'),
				value: phone,
				required: true
			},
			{
				widget: FormLines.IDS.EMAIL,
				id: FORM_IDS.EMAIL,
				label: this.localize('Base.formEmailLabel'),
				value: login,
				required: true
			},
			{
				widget: FormLines.IDS.TEXTAREA,
				id: FORM_IDS.MESSAGE,
				label: this.localize('DemandForm.messageLabel'),
				required: true,
				value: this.props.defaultMessage
			}
		])
	}

	render() {
		const { isSending, formLineEntities } = this.state

		return (
			<Form
				className={this.cssClasses({
					[`${this.props.className}`]: !!this.props.className,
					[`${CLASSNAME}`]: true
				})}
				formLineEntities={formLineEntities}
				isSending={isSending}
				renderWidgets={this.renderWidgets}
				onSubmit={this.onSubmit}
				renderSubmitButton={this.renderSubmitButton}
			/>
		)
	}

	renderSubmitButton(props) {
		const { isSending } = this.state

		return (
			<Button
				{...props}
				loading={isSending}
				primary={true}
				text={this.localize('DemandForm.submit')}
				className={`${CLASSNAME}__submit`}
				data-dot='submit'
			/>
		)
	}

	renderWidgets() {
		const termsLink = 'https://o-seznam.cz/pravni-informace/ochrana-udaju/souhlas/'
		return (
			<>
				{FormLines.renderWidgetsByIds(
					this.state.formLineEntities,
					{
						onChange: this.onChange,
						onBlur: this.onBlur
					},
					SZNDS_WIDGET_BY_ID
				)}
				<div className={`${CLASSNAME}__data-processing`}>
					{this.localize('DemandForm.dataProcessing')}
					<a href={termsLink} className={`${CLASSNAME}__link`}>
						{this.localize('DemandForm.terms')}
					</a>
				</div>
			</>
		)
	}

	onChange(event, newValue, id) {
		this.setState((state, props) => ({
			formLineEntities: FormLines.updateEntities(state.formLineEntities, [
				{
					id,
					value: newValue
				}
			])
		}))
	}

	onBlur(event, id) {
		this.setState((state, props) => {
			const formLineEntity = FormLines.getFormLineById(id, state.formLineEntities)

			return {
				formLineEntities: FormLines.updateEntities(state.formLineEntities, [
					{
						id,
						errorMessage: this.validate(formLineEntity)
					}
				])
			}
		})
	}

	onSubmit(event) {
		const validationErrors = FormLines.validate(this.state.formLineEntities, {
			[FORM_IDS.NAME]: this.validate,
			[FORM_IDS.EMAIL]: this.validate,
			[FORM_IDS.PHONE]: this.validate,
			[FORM_IDS.MESSAGE]: this.validate
		})

		this.setState(
			(state, props) => ({
				formLineEntities: FormLines.updateEntities(state.formLineEntities, validationErrors)
			}),
			() => {
				if (FormLines.isSomeError(validationErrors)) {
					FormLines.focusFirstInvalidInput(this.state.formLineEntities)
				} else {
					this.setState(
						{
							isSending: true
						},
						() => {
							const formData = FormLines.getFormData(this.state.formLineEntities)

							this.props.onSubmit(formData, this.onSubmitSuccess, this.onSubmitError)
						}
					)
				}
			}
		)
	}

	onSubmitSuccess() {
		this._sendContactSellerAnalyticsData('SendForm')

		this.setState({
			isSending: false
		})

		this.props.onSuccess()
	}

	onSubmitError(errors = []) {
		const formLinesErrors = FormError.checkErrors(errors)

		this.setState((state) => ({
			isSending: false,
			formLineEntities: FormLines.updateEntities(state.formLineEntities, formLinesErrors)
		}))

		this.props.onError(this.localize('DemandForm.formSendError'))
	}

	validate(formLineEntity) {
		const { id, value } = formLineEntity

		if (!Validation.isNotEmpty(value)) {
			const emptyErrorMessages = {
				[FORM_IDS.NAME]: this.localize('DemandForm.nameErrorEmpty'),
				[FORM_IDS.EMAIL]: this.localize('Base.formEmailErrorEmpty'),
				[FORM_IDS.PHONE]: this.localize('DemandForm.phoneErrorEmpty'),
				[FORM_IDS.MESSAGE]: this.localize('DemandForm.messageErrorEmpty')
			}

			return emptyErrorMessages[id] || ''
		} else {
			if (id === FORM_IDS.EMAIL && !Validation.checkEmail(value)) {
				return this.localize('Base.formEmailErrorValue')
			} else if (id === FORM_IDS.PHONE && !Validation.checkPhone(value)) {
				return this.localize('DemandForm.phoneErrorValue')
			} else {
				return ''
			}
		}
	}

	_sendContactSellerAnalyticsData(type) {
		const { getLocationOnPage } = this.props
		const { AnalyticsDataSender } = this.utils
		const { route } = this.utils.$Router.getCurrentRouteInfo()
		const currentRouteName = route.getName()

		AnalyticsDataSender.hitContactSeller({
			type,
			page: currentRouteName,
			location: getLocationOnPage() || SellerCard.LOCATION_ON_PAGE.STATIC
		})
	}
}
