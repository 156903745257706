
		(function (root) {
			var $IMA = {};
			root.$IMA = root.$IMA || {};
			$IMA = root.$IMA;
			$IMA.i18n = $IMA.i18n || {};
			
$IMA.i18n['AdvertDetail'] = {
  reviewTitle: function(d) { return "Zkušenosti majitelů:"; },
  shareCopied: function(d) { return "Odkaz inzerátu byl zkopírován do schránky"; },
  certificates: function(d) { return "Certifikáty"; }
};
			return $IMA.i18n;
		})(typeof window !== 'undefined' && window !== null ? window : global);
	