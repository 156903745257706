import React from 'react'
import PropTypes from 'prop-types'
import { Tooltip, ActionElement } from '@inzeraty/components'
import { useLocalize } from 'app/base/componentHelpers'
import classnames from 'classnames'

import './SortingTooltipCS.json'
import './SortingTooltip.less'
import 'app/base/BaseCS.json'

const CLASSNAME = 'c-sorting-tooltip'

const SortingTooltip = ({ className = '' }) => {
	const localize = useLocalize()

	return (
		<Tooltip
			dictionary={{
				ariaClose: localize('Base.close'),
				blindClosePrefix: localize('SortingTooltip.blindClosePrefix'),
				ariaOpener: localize('SortingTooltip.ariaOpener')
			}}
			allowContentMouseOver={true}
			hideHeader={true}
			position={Tooltip.POSITION.LEFT_BOTTOM}
			modalPosition={Tooltip.MODAL_POSITION.CENTER}
			modalHideHeader={true}
			className={classnames({
				[CLASSNAME]: true,
				[className]: !!className
			})}
		>
			<div className={`${CLASSNAME}__content`}>
				<p>{localize('SortingTooltip.description')}</p>
				<ActionElement
					href='https://o-seznam.cz/napoveda/sauto/prihlaseni-firemni-inzerce/technicke-podminky/'
					target='_blank'
					rel='noreferrer noopener'
				>
					{localize('SortingTooltip.moreInfo')}
				</ActionElement>
			</div>
		</Tooltip>
	)
}

SortingTooltip.propTypes = {
	className: PropTypes.string
}

export default SortingTooltip
