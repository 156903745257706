import React, { useState, useEffect, useContext } from 'react'
import ImaContext from 'ima/page/context'
import IMAGES from 'app/base/ImagesConstants'
import { useLocalize } from 'app/base/componentHelpers'
import Modal from 'app/component/modal/ModalView'
import TextLink from 'app/component/textLink/TextLink'
import { IE_MODAL_CLOSED_SESSION } from 'app/base/StorageIds'

import './DialogInternetExplorer.less'
import './DialogInternetExplorerCS.json'

const CLASSNAME = 'c-dialog-ie'

export const isIE = () =>
	navigator.userAgent.indexOf('MSIE ') > -1 || navigator.userAgent.indexOf('Trident/') > -1

export default function DialogInternetExplorer() {
	const { $SessionStorage } = useContext(ImaContext)
	const [isOpen, setIsOpen] = useState(false)
	const localize = useLocalize()

	useEffect(() => {
		if (isIE() && !$SessionStorage.has(IE_MODAL_CLOSED_SESSION)) {
			setIsOpen(true)
		}
	}, [])

	const handleIsOpenCallback = (isOpen) => {
		if (!isOpen) {
			$SessionStorage.set(IE_MODAL_CLOSED_SESSION, 'true')
			setIsOpen(false)
		}
	}

	if (isOpen) {
		return (
			<Modal
				isOpen={isOpen}
				title={localize('DialogInternetExplorer.title')}
				className={CLASSNAME}
				modalPosition={Modal.POSITION.CENTER}
				modalStyle={Modal.STYLE.OFFSET}
				setIsOpenCallback={handleIsOpenCallback}
			>
				<div className={`${CLASSNAME}__content`}>
					<img src={IMAGES['image__ie']} alt='' className={`${CLASSNAME}__img`} />
					<div className={`${CLASSNAME}__info`}>
						<p className={`${CLASSNAME}__text`}>{localize('DialogInternetExplorer.text')}</p>
						<p>
							<TextLink href='https://o-seznam.cz/napoveda/sauto/dulezite-pro-vsechny/podporovane-prohlizece/'>
								{localize('DialogInternetExplorer.whatNext')}
							</TextLink>{' '}
							{localize('DialogInternetExplorer.try')}{' '}
							<TextLink href='https://www.seznam.cz/prohlizec/'>
								{localize('DialogInternetExplorer.sBrowser')}
							</TextLink>
						</p>
					</div>
				</div>
			</Modal>
		)
	} else {
		return null
	}
}
