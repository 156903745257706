import React, { useContext, useEffect, useRef } from 'react'
import { useLocalize } from 'app/base/componentHelpers'
import PropTypes from 'prop-types'
import { Surface, Button } from '@sznds/react'
import { Format } from '@inzeraty/helpers'
import { Responsive } from '@inzeraty/components'
import Context from 'ima/page/context'
import { RESPONSIVE } from 'app/base/Constants'
import IMAGES from 'app/base/ImagesConstants'
import { CATEGORIES } from 'app/base/Constants'
import isNullOrUndefined from 'app/helpers/isNullOrUndefined/IsNullOrUndefined'
import { FirstViewLazyFire } from '../firstViewLazyFire/FirstViewLazyFire'

import './CarInsurance.less'
import './CarInsuranceCS.json'

const INSURANCE_URL =
	'https://www.klik.cz/povinne-ruceni-rychla-kalkulacka?utm_source=seznam&utm_medium=direct&utm_campaign=sauto_2307'
const INSURANCE_PAID = 'Klik.cz & ePojisteni.cz, s.r.o.'
const INSURANCE_BEHALF = 'Klik.cz & ePojisteni.cz, s.r.o.'

const CLASSNAME = 'car-insurance'

export function CarInsurance({ advertEntity = {}, isOperatingLeasePage, mtplPrices }) {
	const { engineVolume, id } = advertEntity

	const coopInfoRef = useRef()
	const localize = useLocalize()

	const context = useContext(Context)
	const sspHelper = context.SspHelper

	const coopInfoElement = coopInfoRef.current

	useEffect(() => {
		if (coopInfoElement) {
			sspHelper.appendCoopIconToElement(coopInfoElement, {
				paid: INSURANCE_PAID,
				behalf: INSURANCE_BEHALF,
				align: 'right',
				disableAnimation: true
			})
		}

		return () => {
			if (coopInfoElement) {
				coopInfoElement.innerHTML = ''
			}
		}
	}, [sspHelper, coopInfoElement])

	if (
		advertEntity?.category?.id !== CATEGORIES.PASSENGER_CARS.id ||
		isOperatingLeasePage ||
		!engineVolume
	) {
		return null
	}

	const { maxPrice, minPrice } = mtplPrices || {}

	const renderPrice = () => {
		if (!isNullOrUndefined(minPrice) && !isNullOrUndefined(maxPrice)) {
			return `${Format.number(minPrice)} ${localize('Base.CZK')} –⁠ ${Format.number(
				maxPrice
			)} ${localize('Base.CZK')}`
		} else {
			return <div className={`${CLASSNAME}__price--plh`}></div>
		}
	}

	const renderInsuranceButtonWithText = (buttonText) => {
		return (
			<Button
				primary={true}
				size='small'
				className={`${CLASSNAME}__external-link-btn`}
				text={buttonText}
				href={_getInsuranceExternalLink(advertEntity)}
				target='_blank'
				rel='noopener'
				onClick={() => {
					this._utils.DotAnalytic.hit({
						action: 'car-insurance',
						actionType: 'click-to-external-url'
					})
				}}
			/>
		)
	}

	return (
		<FirstViewLazyFire advertId={id} fireEvent='carInsuranceHit'>
			<Surface tagName='div' surface={5} className={`${CLASSNAME}__wrapper`} id='insurance'>
				<div className={`${CLASSNAME}__header-wrapper`}>
					<div className={`${CLASSNAME}__header`}>{localize(`CarInsurance.header`)}</div>
					<img
						src={IMAGES['image__insurance-klik']}
						className={`${CLASSNAME}__seller-logo`}
						alt={localize(`CarInsurance.header`)}
					/>
				</div>
				<div>{localize(`CarInsurance.range`)}</div>
				<div className={`${CLASSNAME}__price`}>{renderPrice()}</div>

				<Responsive
					breakpoint={RESPONSIVE.MOBILE_MEDIUM}
					renderMobileElement={() => {
						return renderInsuranceButtonWithText(localize(`CarInsurance.moreInfoMobile`))
					}}
					renderDesktopElement={() => {
						return renderInsuranceButtonWithText(localize(`CarInsurance.moreInfo`))
					}}
				/>
			</Surface>
			<div className={`${CLASSNAME}__coop-info`} ref={coopInfoRef}></div>
		</FirstViewLazyFire>
	)
}

CarInsurance.propTypes = {
	advertEntity: PropTypes.object,
	isOperatingLeasePage: PropTypes.bool,
	mtplPrices: PropTypes.object
}

function _getRegisteredDate(inOperationDate) {
	const registeredDate = new Date(inOperationDate)
	const year = registeredDate.getFullYear()
	const month = registeredDate.getMonth() + 1
	const day = registeredDate.getDate()

	return `${year}-${month}-${day}`
}

function _getInsuranceExternalLink(advertEntity) {
	const {
		manufacturerCb: { name: manufacturerName = '' } = {},
		modelCb: { name: modelName = '' } = {},
		engineVolume,
		enginePower,
		price,
		tachometer,
		capacity,
		inOperationDate,
		manufacturingDate,
		weight
	} = advertEntity

	let externalLink = INSURANCE_URL

	if (manufacturerName) externalLink += `&brand=${manufacturerName}`
	if (modelName) externalLink += `&model=${modelName}`
	if (capacity) externalLink += `&seats=${capacity}`
	if (engineVolume) externalLink += `&engine=${engineVolume}`
	if (enginePower) externalLink += `&power=${enginePower}`
	if (manufacturingDate)
		externalLink += `&construction_year=${new Date(manufacturingDate).getFullYear()}`
	if (inOperationDate) externalLink += `&registered=${_getRegisteredDate(inOperationDate)}`
	if (weight) externalLink += `&weight=${weight}`
	if (price) externalLink += `&value=${price}`
	if (tachometer) externalLink += `&car_km=${tachometer}`

	return externalLink
}
