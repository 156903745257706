import AbstractComponent from 'app/base/AbstractComponent'
import React from 'react'
import Layout from 'app/component/layout/LayoutView'
import AdvertGallery from 'app/page/userweb/advertDetail/advertGallery/AdvertGallery'
import AdvertBasicInfo from 'app/page/userweb/advertDetail/advertBasicInfo/AdvertBasicInfo'
import SellerContactSection from 'app/page/userweb/advertDetail/sellerContactSection/SellerContactSection'
import CarProperties from 'app/page/userweb/advertDetail/carProperties/CarProperties'
import CarDetails from 'app/page/userweb/advertDetail/carDetails/CarDetails'
import { Surface } from '@sznds/react'
import Square, { RICH_MEDIA_COLLOCATION } from 'app/component/sspAdverts/square/SquareView'
import Skyscraper from 'app/component/sspAdverts/skyscraper/Skyscraper'
import Subheader from 'app/page/userweb/advertDetail/detailSubheader/DetailSubheaderView'
import PreviewMessage from './previewMessage/PreviewMessage'
import STATE_KEYS from './AdvertDetailStateKeys'
import Reviews from 'app/component/reviews/Reviews'
import RelatedAdverts from './relatedAdverts/RelatedAdverts'
import { Share, StatusMessage } from '@inzeraty/components'
import { SHOW_NEW_TOAST_MESSAGE_EVENT } from 'app/component/toastMessages/ToastsHooks'
import Favorite from 'app/component/favorite/Favorite'
import OtherActions from 'app/page/userweb/advertDetail/otherActions/OtherActions'
import CarCompare from 'app/component/carCompare/CarCompare'
import Calculator from 'app/component/sspAdverts/calculator/Calculator'
import InactiveAdvert from './inactiveAdvert/InactiveAdvert'
import Articles from 'app/page/userweb/advertDetail/articles/Articles'
import { SHOW_CAR_INSURANCE } from 'app/base/Constants'
import { CarInsurance } from './carInsurance/CarInsurance'
import { FirstViewLazyFire } from './firstViewLazyFire/FirstViewLazyFire'

import '@inzeraty/components/dist/components/share/ShareCS.json'
import './AdvertDetailView.less'
import './AdvertDetailCS.json'

const CLASSNAME = 'p-uw-item-detail'

/**
 * Advert page
 * @class AdvertDetailView
 * @namespace app.page.userweb.advertDetail
 * @extends ima.page.AbstractComponent
 * @module app
 * @submodule app.page
 */
export default class AdvertDetailView extends AbstractComponent {
	constructor(props, context) {
		super(props, context)

		this._shareCopyCallback = this._shareCopyCallback.bind(this)
	}

	componentDidMount() {
		document.body.setAttribute('data-dot', 'advert-detail')
	}

	componentWillUnmount() {
		document.body.removeAttribute('data-dot')
	}

	render() {
		const {
			[STATE_KEYS.HTTP_STATUS]: httpStatus,
			[STATE_KEYS.ADVERT_ENTITY]: advertEntity,
			[STATE_KEYS.IS_INACTIVE_MODE]: isInactiveMode = false,
			[STATE_KEYS.IS_PREVIEW_MODE]: isPreviewMode = false
		} = this.props

		const renderedAdvertDetail = this._renderContent()

		return (
			<Layout className={CLASSNAME} httpStatus={httpStatus}>
				{isInactiveMode ? (
					<InactiveAdvert advertEntity={advertEntity}>{renderedAdvertDetail}</InactiveAdvert>
				) : (
					<>
						{isPreviewMode && <PreviewMessage />}
						{renderedAdvertDetail}
					</>
				)}
			</Layout>
		)
	}

	_renderReviews() {
		const {
			advertEntity: { namePrefix = '' } = {},
			[STATE_KEYS.IS_ADVERT_ENTITY_LOADED]: isAdvertEntityLoaded = false
		} = this.props

		return (
			<>
				<h2 className={`${CLASSNAME}__review-title`}>
					{this.localize('AdvertDetail.reviewTitle')}{' '}
					<span className={`${CLASSNAME}__review-car-name`}>
						{namePrefix.trim().replace(/,$/, '')}
					</span>
				</h2>
				<Reviews isLazyLoaded={true} isAdvertEntityLoaded={isAdvertEntityLoaded} />
			</>
		)
	}

	_renderArticles() {
		const { [STATE_KEYS.IS_ADVERT_ENTITY_LOADED]: isAdvertEntityLoaded = false } = this.props

		if (!isAdvertEntityLoaded) return null

		const {
			[STATE_KEYS.ADVERT_ENTITY]: {
				id: advertId,
				category: { id: categoryId } = {},
				modelCb: { value: modelId } = {},
				manufacturerCb: { value: manufacturerId } = {}
			} = {},
			[STATE_KEYS.ARTICLES]: articles
		} = this.props

		if (!modelId || !manufacturerId || !categoryId) return null

		const fireParams = {
			model_cb: modelId,
			category_id: categoryId,
			manufacturer_cb: manufacturerId
		}

		return (
			<FirstViewLazyFire advertId={advertId} fireEvent='fetchArticles' fireEventParams={fireParams}>
				<Articles articles={articles?.entities} />
			</FirstViewLazyFire>
		)
	}

	_shareCopyCallback() {
		this.utils.$Dispatcher.fire(SHOW_NEW_TOAST_MESSAGE_EVENT, {
			title: this.localize(`AdvertDetail.shareCopied`),
			type: StatusMessage.TYPE.SUCCESS
		})
	}

	_renderActions() {
		const { advertEntity: { id: advertId } = {} } = this.props

		return (
			<div className={`${CLASSNAME}__actions`}>
				<Favorite id={advertId} type={Favorite.TYPE.BUTTON} />
				{this._renderShare()}
				<OtherActions />
			</div>
		)
	}

	_renderShare() {
		return (
			<Share
				className={`${CLASSNAME}__action-share`}
				dictionary={{
					btnText: this.localize('Share.btnText'),
					title: this.localize('Share.title'),
					label: this.localize('Share.label'),
					facebook: this.localize('Share.facebook'),
					twitter: this.localize('Share.twitter'),
					copy: this.localize('Share.copy'),
					close: this.localize('Share.close'),
					blindClosePrefix: this.localize('Share.blindClosePrefix'),
					ariaOpener: this.localize('Share.ariaOpener'),
					ariaTitle: this.localize('Share.ariaTitle')
				}}
				url={this.utils.$Router.getUrl()}
				copyCallback={this._shareCopyCallback}
				position={Share.POSITION.BOTTOM_LEFT}
			/>
		)
	}

	_renderContent() {
		const {
			[STATE_KEYS.IS_OPERATING_LEASE_PAGE]: isOperatingLeasePage,
			[STATE_KEYS.IS_INACTIVE_MODE]: isInactiveMode = false,
			[STATE_KEYS.ADVERT_ENTITY]: advertEntity = {},
			[STATE_KEYS.IS_PREVIEW_MODE]: isPreviewMode = false,
			[STATE_KEYS.CAR_COMPARE]: carCompareData,
			[STATE_KEYS.MTLP_PRICES]: mtplPrices
		} = this.props
		const { id } = advertEntity
		const isFavoriteAndActionBarDisplayed = !isPreviewMode && !isInactiveMode

		return (
			<div className={`${CLASSNAME}__content`}>
				<Subheader />

				<div className={`${CLASSNAME}__car-column`}>
					<AdvertGallery
						isAdvertInactive={isInactiveMode}
						isFavoriteDisplayed={isFavoriteAndActionBarDisplayed}
					/>

					<div className={`${CLASSNAME}__wrap`}>
						<Surface tagName='div' surface={5} className={`${CLASSNAME}__info`}>
							<AdvertBasicInfo {...this.props} />
							<CarProperties {...this.props} />
							{isFavoriteAndActionBarDisplayed && this._renderActions()}
						</Surface>
						{!isInactiveMode ? (
							<Square
								id='ssp-square-detail'
								className={`${CLASSNAME}__square`}
								width={720}
								height={1280}
								extraCollocation={`${RICH_MEDIA_COLLOCATION} detail`}
							/>
						) : (
							<div className={`${CLASSNAME}__square--empty`} />
						)}
					</div>

					{!isInactiveMode && <Calculator />}

					<CarDetails className={`${CLASSNAME}__info`} />

					{!isInactiveMode && (
						<>
							{!isOperatingLeasePage && (
								<FirstViewLazyFire advertId={id} fireEvent='setCarCompare'>
									<CarCompare advertEntity={advertEntity} compareData={carCompareData} />
								</FirstViewLazyFire>
							)}
							<FirstViewLazyFire
								advertId={id}
								isOperatingLease={isOperatingLeasePage}
								fireEvent='setPremiseAdvertsCount'
							>
								<SellerContactSection />
							</FirstViewLazyFire>

							{SHOW_CAR_INSURANCE && (
								<CarInsurance
									advertEntity={advertEntity}
									isOperatingLeasePage={isOperatingLeasePage}
									mtplPrices={mtplPrices}
								/>
							)}

							<RelatedAdverts
								isAdvertEntityLoaded={this.props[STATE_KEYS.IS_ADVERT_ENTITY_LOADED]}
								advertEntity={this.props[STATE_KEYS.ADVERT_ENTITY]}
								isOperatingLease={isOperatingLeasePage}
								relatedAdvertEntities={this.props[STATE_KEYS.RELATED_ADVERT_ENTITIES]}
							/>

							<Calculator.MobileRenderer />

							{this._renderArticles()}

							<Surface tagName='div' surface={5} className={`${CLASSNAME}__other-info`}>
								{this._renderReviews()}
							</Surface>

							<div className={`${CLASSNAME}__skyscraper`}>
								<Skyscraper />
							</div>
						</>
					)}
				</div>
			</div>
		)
	}
}
