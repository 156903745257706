import { Events as AnalyticEvents } from '@ima/plugin-analytic'

export default class SspHelper {
	/**
	 * Vrátí className úspěšně vložené reklamy.
	 *
	 * @static
	 * @method adFullClassName
	 * @return {string}
	 */
	static get adFullClassName() {
		return 'adFull'
	}

	/**
	 * Vrati jednotlive custom evnty, ktore je mozne posielat na reklamu
	 * DESTROY - po zavolani si reklama po sebe "uprace" - odstrani elementy ktore
	 * napriklad apendla do body (napriklad video)
	 * PAGE_OFFSET - vyuziva sa napriklad ako listener, ktory je naviazany na video reklamu
	 * kde mozeme odchytavat ci je video pustene alebo zastavene
	 */
	static get SSP_CUSTOM_EVENTS() {
		return {
			DESTROY: 'seznam.im3.spot.destroy',
			PAGE_OFFSET: 'seznam.im3.spot.pageoffset'
		}
	}

	/**
	 * @constructor
	 * @param {$Settings}    $settings
	 * @param {$Window}      $window
	 * @param {$Dispatcher}  $dispatcher
	 * @param {ScriptLoader} scriptLoader
	 * @param {DotAnalytic}  dotAnalytic
	 */
	constructor($settings, $window, $dispatcher, scriptLoader, dotAnalytic) {
		this._config = $settings.ssp
		this._window = $window
		this._dispatcher = $dispatcher
		this._scriptLoader = scriptLoader
		this._dotAnalytic = dotAnalytic

		/**
		 * Určuje jestli je ssp aktivní pro použití - jsou stažené všechny potřebné závislosti.
		 * @type {boolean}
		 */
		this._isEnabled = false

		/**
		 * Určuje jestli by mělo dojít k provolání sssp.getAdsByTags().
		 * @type {boolean}
		 */
		this._shouldGetAdsByTags = false

		/**
		 * Uchovává nadefinované pozice, které jsou předány při volání sssp.getAds(pozice).
		 * @type {array<Object>}
		 */
		this._pendingRequests = []

		/**
		 * Identifikátor časovače, který spouští žádání o reklamu ve chvíli kdy je ssp aktivní.
		 * @type {timeoutID}
		 */
		this._flushTimeoutId = null

		/**
		 * Timeout (milisekundy) pro spuštění kontroly jestli je ssp aktivní a může žádat o reklamu.
		 * @type {number}
		 */
		this._flushTimeout = 50
	}

	/**
	 * Provede inicializaci ssp.
	 *
	 * @method init
	 * @public
	 */
	init() {
		this._install()

		// Jelikož DOT nemusí být načtený ve chvíli konfigurace, tak na něj počká a pak zkusí znovu nakonfigurovat.
		if (!this.isEnabled() && this._window.isClient()) {
			this._dispatcher.listen(AnalyticEvents.LOADED, (data) => {
				if (data.type === 'dot') {
					this._configure()
				}
			})
		}
	}

	/**
	 * Vrátí informaci jestli je ssp aktivní.
	 *
	 * @method isEnabled
	 * @public
	 * @return {boolean}
	 */
	isEnabled() {
		return this._isEnabled
	}

	/**
	 * Zažádá o reklamu na základě data atributu pro ssp u html elementů.
	 *
	 * @method getAdsByTags
	 * @public
	 */
	getAdsByTags() {
		this._shouldGetAdsByTags = true
		this._flush()
	}

	/**
	 * Zažádá o reklamu na základě nadefinovaných pozic.
	 *
	 * @method getAds
	 * @public
	 * @param {Array<Object>} positions Reklamní pozice.
	 */
	getAds(positions = []) {
		this._pendingRequests = [...this._pendingRequests, ...positions]

		clearTimeout(this._flushTimeoutId)

		this._flushTimeoutId = setTimeout(() => {
			this._flush()
		}, this._flushTimeout)
	}

	/**
	 * Vlozi reklamu do stranky.
	 *
	 * @method writeAd
	 * @public
	 * @param {Object} sspAdvert
	 * @param {Object} zone
	 */
	writeAd(sspAdvert, zone) {
		try {
			sssp.writeAd(sspAdvert, zone)
		} catch (error) {
			this._error('writeAd', error)
		}
	}

	/**
	 * "Vyčistí" element od reklamy.
	 *
	 * @method cleanElement
	 * @public
	 * @param {Element} element
	 */
	cleanElement(element) {
		if (element) {
			element.removeAttribute('style')
			element.classList.remove(SspHelper.adFullClassName)
			element.innerHTML = ''
		}
	}

	/**
	 * "Vyčistí" info o odeslani visibleImpress
	 *
	 * @method resetVisibilityElements
	 * @public
	 */
	resetVisibilityElements() {
		try {
			this._window.getWindow().sssp.setPageViewId(null, true)
		} catch (error) {
			this._error('resetVisibilityElements', error)
		}
	}

	/**
	 * "Vycisti" data ktore pridu z reklamy
	 * pre ADVERT chodi nevalidni JSON,
	 * proto ho musime pred parsovanim nejdriv upravit
	 * @param {String} data data z reklamy
	 */
	sanitizeJsonAdvertData(data) {
		return data.trim().replace(/<!--[^(-->)]*-->/g, '')
	}

	/**
	 * Vytvori element img ktoremu nastavi src a tym posle impress
	 * @param {String} src zdroj imprese
	 */
	measureByImg(src) {
		const divEl = document.createElement('div')
		const imgEl = document.createElement('img')
		imgEl.src = src
		divEl.appendChild(imgEl)
	}

	/**
	 * Vlozeni informacni ikony ke spolupracujicim odkazum
	 * @method appendCoopIconToElement
	 * @public
	 * @param {Element} element - element kam se ma coopicon vlozit
	 * @param {Object} params
	 * @param {String} params.paid - platce
	 * @param {String} params.behalf - zadavatel
	 * @param {String} params.align - left | right
	 * @param {boolean} params.disableAnimation
	 */
	appendCoopIconToElement(element, params) {
		try {
			sssp.appendCoopIconToElement(params, element)
		} catch (error) {
			this._error('appendCoopIconToElement', error)
		}
	}

	/**
	 * Vlozeni ikony tlapky k reklame
	 * @method appendPawToElement
	 * @public
	 * @param {Object} advert - reklama
	 * @param {Element} element - element kam se ma pawicon vlozit
	 */
	appendPawToElement(advert, element) {
		try {
			sssp.appendPawToElement(advert, element)
		} catch (error) {
			this._error('appendPawToElement', error)
		}
	}

	/**
	 * Zažádá o reklamu na základě nadefinovaných pozic.
	 *
	 * @method _getAds
	 * @private
	 */
	_getAds() {
		this.resetVisibilityElements()

		try {
			this._window.getWindow().sssp.getAds(this._pendingRequests)
		} catch (error) {
			this._error('getAds', error)
		}

		this._pendingRequests = []
	}

	/**
	 * Zažádá o reklamu na základě data atributu pro ssp u html elementů.
	 *
	 * @method _getAdsByTags
	 * @private
	 */
	_getAdsByTags() {
		try {
			this._window.getWindow().sssp.getAdsByTags()
		} catch (error) {
			this._error('getAdsByTags', error)
		}

		this._shouldGetAdsByTags = false
	}

	/**
	 * Provede instalaci (stažení) ssp scriptu.
	 *
	 * @method _install
	 * @private
	 */
	_install() {
		const { url } = this._config
		if (!this.isEnabled() && this._window.isClient() && url) {
			this._scriptLoader.load(url).then(() => {
				this._configure()
			})
		}
	}

	/**
	 * Nastaví ssp.
	 *
	 * @method _configure
	 * @private
	 */
	_configure() {
		if (
			!this._window.isClient() ||
			!this._window.getWindow().sssp ||
			!this._dotAnalytic.isEnabled() ||
			!this._config.server ||
			this.isEnabled()
		) {
			return
		} else {
			this._isEnabled = true

			try {
				const { site, server, protocol, badge } = this._config
				const config = {
					server
				}

				if (site) {
					config.site = `${site}/`
				}

				if (protocol) {
					config.protocol = protocol
				}

				// Určuje, zda se ssp script má pokusit získat rusId a premium atributy z medailonku
				if (badge) {
					config.badge = badge
				}

				this._window.getWindow().sssp.config(config)
			} catch (error) {
				this._error('Ssp configuration', error)
			}
		}
	}

	/**
	 * Provode provolání žádostí na reklamu. Ve chvíli kdy ssp není aktivní nastaví timer po jehož uplynutí zkuší žádost znovu.
	 *
	 * @method _flush
	 * @private
	 */
	_flush() {
		clearTimeout(this._flushTimeoutId)

		if (this.isEnabled()) {
			if (this._shouldGetAdsByTags) {
				this._getAdsByTags()
			}

			if (this._pendingRequests.length) {
				this._getAds()
			}
		} else {
			this._flushTimeoutId = setTimeout(() => {
				this._flush()
			}, this._flushTimeout)
		}
	}

	/**
	 * Ošetření chyby.
	 *
	 * @method _error
	 * @private
	 * @param {String} description Popis chyby.
	 * @param {Error}  error       Chyba.
	 */
	_error(description, error) {
		console.error(description, error)
	}
}
