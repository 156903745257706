import React from 'react'
import { Checkbox, Surface } from '@sznds/react'
import { useLocalize } from 'app/base/componentHelpers'
import select from 'ima-plugin-select'
import PAYMENT_STATE_KEYS from '../../PaymentStateKeys'
import PropTypes from 'prop-types'

import './TermsAndConditionsCS.json'
import './TermsAndConditions.less'

const CLASSNAME = 'c-terms-and-conditions'

const TermsAndConditions = (props) => {
	const localize = useLocalize()

	const { termsAndConditionsChecked, setTermsAndConditions } = props

	const renderLabel = () => {
		const linkUrl = 'https://o-seznam.cz/napoveda/sauto/dulezite-pro-vsechny/smluvni-podminky/'
		return (
			<div className={`${CLASSNAME}__label`}>
				<span>{localize('TermsAndConditions.text')}</span>
				<a
					href={linkUrl}
					className={`${CLASSNAME}__link`}
					rel='noopener noreferrer'
					target='_blank'
				>
					{localize('TermsAndConditions.link')}
				</a>
			</div>
		)
	}

	const renderContent = () => (
		<label>
			<div className={`${CLASSNAME}__input`}>
				<Checkbox
					checked={termsAndConditionsChecked}
					data-e2e='terms-and-condition-checkbox'
					onChange={(event) => {
						event.stopPropagation()
						setTermsAndConditions(!termsAndConditionsChecked)
					}}
				/>
				{renderLabel()}
			</div>
		</label>
	)

	const renderedContent = renderContent()

	return (
		<>
			<Surface surface={5} className={`${CLASSNAME}__desktop`}>
				{renderedContent}
			</Surface>

			<div className={`${CLASSNAME}__mobile`}>{renderedContent}</div>
		</>
	)
}

const selectors = (state) => ({
	termsAndConditionsChecked: state[PAYMENT_STATE_KEYS.TERMS_AND_CONDITIONS_CHECKED],
	setTermsAndConditions: state[PAYMENT_STATE_KEYS.SET_TERMS_AND_CONDITIONS]
})

TermsAndConditions.propTypes = {
	termsAndConditionsChecked: PropTypes.bool,
	setTermsAndConditions: PropTypes.func
}

export default React.memo(select(selectors)(TermsAndConditions))
