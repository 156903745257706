import React, { useState, useEffect, useContext } from 'react'
import LocalitySelectInputExtension from './LocalitySelectInputExtension'
import AutoComplete from 'app/component/autoComplete/AutoComplete'
import { useLocalize } from 'app/base/componentHelpers'
import { localityPropTypes, LocalitySuggestEntity } from '@inzeraty/models'
import { Responsive } from '@inzeraty/components'
import { RESPONSIVE } from 'app/base/Constants'
import AutoCompleteInput from 'app/component/autoComplete/AutoCompleteInput'
import AutoCompleteOption from 'app/component/autoComplete/AutoCompleteOption'
import IMAGES from 'app/base/ImagesConstants'
import select from 'ima-plugin-select'
import { SuggestLocalityCore } from '@inzeraty/components'
import Context from 'ima/page/context'
import SvgIcon from 'app/component/atoms/svgIcon/SvgIconView'
import classnames from 'classnames'
import { DefaultProps as DEFAULT_PROPS } from '@inzeraty/helpers'
import PropTypes from 'prop-types'
import * as FormLines from '@inzeraty/form-lines'
import LocalityHelper from 'app/component/locality/LocalityHelper'

import './LocalitySelectInput.less'
import './LocalitySelectInputCS.json'

const CLASSNAME = 'c-locality-select-input'

const USE_GEOLOCATION_OPTION_KEY = -1

const localityToText = (locality) => {
	const getLocalityTextFromFullData = (locality = {}) =>
		LocalityHelper.localitySentence(locality, ',')

	if (locality instanceof LocalitySuggestEntity) {
		// pro lokalitu vybranou pres select
		const { suggestFirstRow, suggestSecondRow } = locality

		if (suggestSecondRow) {
			return [suggestFirstRow, suggestSecondRow].join(', ')
		} else {
			return suggestFirstRow
		}
	} else {
		// pro lokalitu vybranou pres geolokaci nebo nactenou z
		// ulozeneho inzeratu
		return getLocalityTextFromFullData(locality)
	}
}

const LocalitySelectInput = ({
	className = '',
	formLineEntity,
	fetchMostFrequentLocalities,
	fetchNewSuggestedLocalities,
	cancelGeolocation,
	runGeolocation,
	mostFrequentLocalities,
	suggestedLocalities,
	isGeolocationRunning,
	onChange,
	onFocus = DEFAULT_PROPS.FUNCTION,
	onBlur = DEFAULT_PROPS.FUNCTION,
	widgetProps,
	inputSurfaceProps
}) => {
	const localize = useLocalize()
	const [searchedLocality, setSearchedLocality] = useState(localityToText(formLineEntity.value))

	useEffect(() => {
		fetchMostFrequentLocalities()
	}, [])

	useEffect(() => {
		if (searchedLocality) {
			fetchNewSuggestedLocalities(searchedLocality)
		}
	}, [searchedLocality])

	useEffect(() => {
		const newValue = localityToText(formLineEntity.value)

		if (newValue && newValue !== searchedLocality) {
			setSearchedLocality(newValue)
		}
	}, [formLineEntity])

	const handleInputValueChange = (inputValue = '', stateAndHelpers) => {
		const { type } = stateAndHelpers

		if (
			type !== AutoComplete.stateChangeTypes.mouseUp &&
			type !== AutoComplete.stateChangeTypes.blurInput
		) {
			setSearchedLocality(inputValue)

			cancelGeolocation()

			// dilci zmeny nechceme ukladat do formline, ale
			// az platnou hodnotu
			onChange({ value: {} })
		}
	}

	const handleSelect = (selectedItem, stateAndHelpers) => {
		// downshift z nějakého důvodu při psaní v inputu provede selectnutí prvku `selectedItedm` je ale v tomto případě undefined
		if (typeof selectedItem === 'undefined') return

		if (selectedItem === USE_GEOLOCATION_OPTION_KEY) {
			handleGeoLocationSelect(stateAndHelpers)
		} else {
			handleLocalitySelect(selectedItem)
		}
	}

	const handleGeoLocationSelect = async (stateAndHelpers) => {
		const { openMenu, closeMenu } = stateAndHelpers

		try {
			openMenu()

			const locality = await runGeolocation()

			setSearchedLocality(localityToText(locality))
			onChange({
				value: locality,
				errorMessage: undefined
			})
		} catch (error) {
			setSearchedLocality('')
			onChange({
				value: {},
				errorMessage: localize('LocalitySelectInput.geolocationFailed')
			})
		} finally {
			closeMenu()
		}
	}

	const handleLocalitySelect = (selectedItem) => {
		setSearchedLocality(localityToText(selectedItem))

		onChange({
			value: selectedItem,
			errorMessage: undefined
		})
	}

	const renderInput = (formLineEntity, props, downshift) => {
		const {
			getInputProps,
			getToggleButtonProps,
			getClearButtonProps,

			isOpen,
			inputValue
		} = downshift

		return (
			<div className={`${CLASSNAME}__input-wrapper`}>
				<AutoCompleteInput
					inputProps={getInputProps({
						name: `${props.name}#someHash`, //Ak tu bude len 'locality', Chrome rospozna ze sa jedna o lokalitu a udela autocomplete
						className: props.className,
						placeholder: props.placeholder,
						required: props.required,
						error: !!formLineEntity.errorMessage,
						disabled: formLineEntity.disabled,
						onFocus: () => onFocus(inputValue),
						onBlur: () => onBlur(inputValue)
					})}
					toggleButtonProps={
						!inputValue
							? getToggleButtonProps({
									isOpen
							  })
							: undefined
					}
					clearButtonProps={inputValue ? getClearButtonProps() : undefined}
					inputSurfaceProps={inputSurfaceProps}
				/>

				<Responsive
					breakpoint={RESPONSIVE.TABLET}
					renderMobileElement={() => renderPopup(formLineEntity, downshift)}
					renderDesktopElement={() => renderDropdown(formLineEntity, downshift)}
				/>
			</div>
		)
	}

	const renderDropdown = (formLineEntity, downshift) => {
		const {
			isOpen,
			inputValue,

			getDropdownProps,
			renderDropdown: Dropdown
		} = downshift

		const blockDropdownOpenning = searchedLocality && suggestedLocalities.length === 0

		return (
			isOpen &&
			!blockDropdownOpenning && (
				<Dropdown {...getDropdownProps()}>
					{isGeolocationRunning
						? renderGeolocationSpinner()
						: inputValue
						? renderSuggestedLocalities(suggestedLocalities, downshift)
						: renderMostFrequentLocalities(mostFrequentLocalities, downshift)}
				</Dropdown>
			)
		)
	}

	const renderPopup = (formLineEntity, downshift) => {
		const {
			isOpen,
			inputValue,

			getInputProps,
			getClearButtonProps,
			getPopupProps,
			renderPopup: Popup,

			closeMenu
		} = downshift

		return (
			isOpen && (
				<Popup
					{...getPopupProps({
						title: formLineEntity.label,
						onClose: () => {
							if (!isGeolocationRunning) {
								closeMenu()
							}
						}
					})}
				>
					<div className={`${CLASSNAME}__popup-input-wrapper`}>
						<AutoCompleteInput
							inputProps={getInputProps({
								placeholder: formLineEntity.placeholder,
								autoFocus: true
							})}
							clearButtonProps={inputValue ? getClearButtonProps() : undefined}
							inputSurfaceProps={inputSurfaceProps}
						/>
					</div>

					{isGeolocationRunning
						? renderGeolocationSpinner()
						: inputValue
						? renderSuggestedLocalities(suggestedLocalities, downshift)
						: renderMostFrequentLocalities(mostFrequentLocalities, downshift)}
				</Popup>
			)
		)
	}

	const renderUseGeolocationOption = (downshift) => {
		const { getItemProps, highlightedIndex } = downshift

		return (
			<div className={`${CLASSNAME}__geolocation-container`}>
				<AutoCompleteOption
					{...getItemProps({
						key: USE_GEOLOCATION_OPTION_KEY,
						index: 0,
						item: USE_GEOLOCATION_OPTION_KEY,
						isHighlighted: highlightedIndex === 0,
						className: classnames(className && `${className}-item`, `${CLASSNAME}__item`)
					})}
				>
					<span className={`${CLASSNAME}__name`}>
						{localize('LocalitySelectInput.useGeolocation')}
						<div className={`${CLASSNAME}__item-sub-text`}>
							{localize('LocalitySelectInput.useGeolocationAdditionalText')}
						</div>
					</span>
				</AutoCompleteOption>
			</div>
		)
	}

	const renderMostFrequentLocalities = (mostFrequentLocalities, downshift) => {
		const { getItemProps, highlightedIndex } = downshift

		return (
			<ul>
				{renderUseGeolocationOption(downshift)}

				<div className={`${CLASSNAME}__most-frequent-container`}>
					<div className={`${CLASSNAME}__section-title`}>
						{localize('LocalitySelectInput.mostFrequentLocalities')}
					</div>

					{mostFrequentLocalities.map((freqLocality, index) => (
						// eslint-disable-next-line react/jsx-key
						<AutoCompleteOption
							{...getItemProps({
								key: `${freqLocality.entityType}__${freqLocality.entityId}`,
								index: index + 1,
								item: freqLocality,
								isHighlighted: highlightedIndex === index + 1,
								className: classnames(className && `${className}-item`, `${CLASSNAME}__item`)
							})}
						>
							{freqLocality.suggestFirstRow}
						</AutoCompleteOption>
					))}
				</div>
			</ul>
		)
	}

	const renderSuggestedLocalities = (suggestedLocalities, downshift) => {
		const { getItemProps, highlightedIndex } = downshift

		return (
			<ul>
				{renderUseGeolocationOption(downshift)}

				<div className={`${CLASSNAME}__suggested-container`}>
					{suggestedLocalities.map((suggestedLocality, index) => (
						// eslint-disable-next-line react/jsx-key
						<AutoCompleteOption
							{...getItemProps({
								key: `${suggestedLocality.entityType}__${suggestedLocality.entityId}`,
								index: index + 1,
								item: suggestedLocality,
								isHighlighted: highlightedIndex === index + 1,
								className: classnames(className && `${className}-item`, `${CLASSNAME}__item`)
							})}
						>
							{suggestedLocality.suggestFirstRow}
							<div className={`${CLASSNAME}__item-sub-text`}>
								{suggestedLocality.suggestSecondRow}
							</div>
						</AutoCompleteOption>
					))}
				</div>
			</ul>
		)
	}

	const renderGeolocationSpinner = () => {
		return (
			<div className={`${CLASSNAME}__geolocation-loading-container`}>
				<div className={`${CLASSNAME}__geolocation-images-wrapper`}>
					<img src={IMAGES['image__dual-ring']} className={`${CLASSNAME}__geo-loader`} alt='' />

					<SvgIcon icon='LOCATION' className={`${CLASSNAME}__geo-image`} />
				</div>

				<div className={`${CLASSNAME}__geo-loading-text`}>
					{localize('LocalitySelectInput.geolocationRunning')}
				</div>
			</div>
		)
	}

	return (
		<AutoComplete
			{...AutoComplete.getIdsPropsHelper(formLineEntity.id)}
			inputValue={searchedLocality}
			onInputValueChange={handleInputValueChange}
			onSelect={handleSelect}
		>
			{(downshift) => renderInput(formLineEntity, widgetProps, downshift)}
		</AutoComplete>
	)
}

const withLocalitySuggestions = (Component) => {
	const WrappedComponent = (props) => {
		const restProps = Object.assign({}, props)

		delete restProps.getMostFrequentLocalities
		delete restProps.getLocalitySuggests
		delete restProps.getLocalityByGpsCoordinates

		const context = useContext(Context)

		return (
			<SuggestLocalityCore
				geolocation={context.Geolocation}
				fetchMostFrequentLocalities={props.getMostFrequentLocalities}
				fetchSuggestedLocalities={props.getLocalitySuggests}
				fetchLocalityByGpsCoordinates={props.getLocalityByGpsCoordinates}
			>
				{(data) => <Component {...restProps} {...data} />}
			</SuggestLocalityCore>
		)
	}

	WrappedComponent.propTypes = {
		getMostFrequentLocalities: PropTypes.func,
		getLocalitySuggests: PropTypes.func,
		getLocalityByGpsCoordinates: PropTypes.func
	}

	return WrappedComponent
}

const localitySelectInputSelector = (state) => ({
	getMostFrequentLocalities:
		state[LocalitySelectInputExtension.stateIds.GET_MOST_FREQUENT_LOCALITIES],
	getLocalitySuggests: state[LocalitySelectInputExtension.stateIds.GET_LOCALITY_SUGGESTS],
	getLocalityByGpsCoordinates:
		state[LocalitySelectInputExtension.stateIds.GET_LOCALITY_BY_GPS_COORDINATES]
})

LocalitySelectInput.propTypes = {
	className: PropTypes.string,
	formLineEntity: PropTypes.instanceOf(FormLines.Entity),
	fetchMostFrequentLocalities: PropTypes.func,
	fetchNewSuggestedLocalities: PropTypes.func,
	cancelGeolocation: PropTypes.func,
	runGeolocation: PropTypes.func,
	mostFrequentLocalities: PropTypes.arrayOf(PropTypes.shape(localityPropTypes)),
	suggestedLocalities: PropTypes.arrayOf(PropTypes.shape(localityPropTypes)),
	isGeolocationRunning: PropTypes.bool,
	onChange: PropTypes.func,
	onFocus: PropTypes.func,
	onBlur: PropTypes.func,
	widgetProps: PropTypes.object,
	inputSurfaceProps: PropTypes.object,
	placeholder: PropTypes.bool,
	required: PropTypes.bool,
	name: PropTypes.string
}

export default select(localitySelectInputSelector)(withLocalitySuggestions(LocalitySelectInput))
