import React, { useContext } from 'react'
import Context from 'ima/page/context'
import classnames from 'classnames'
import { useLocalize, useFire } from 'app/base/componentHelpers'
import { Format } from '@inzeraty/helpers'
import ROUTE_NAMES from 'app/base/RouteNames'
import AdvertAdminUrlConvertor from 'app/page/userweb/newAdvert/AdvertAdminUrlConvertor'
import { ADVERT_STATE } from '../getAdvertState'
import { ACTION_TYPE } from '../advertActions/AdvertActionsConstants'
import ActionElm from 'app/component/actionElement/ActionElement'
import PropTypes from 'prop-types'
import advertPropTypes from 'app/model/advert/AdvertPropTypes'

import './StatusLineCS.json'
import './StatusLine.less'

const CLASSNAME = 'c-client-status-line'

const StatusLineView = ({ advertState, advertEntity, isPremise }) => {
	const localize = useLocalize()
	const fire = useFire()
	const context = useContext(Context)
	const router = context.$Router

	const { id: advertId, validToDate, preferredOffer, topped } = advertEntity
	const { URL_APP_PARAMS } = AdvertAdminUrlConvertor.constants

	const getPreferredText = (separator = '') => {
		return preferredOffer ? `${separator}${localize('StatusLine.preferredOffer')}` : ''
	}

	const getToppedText = (separator = '', separatorBehind = '') => {
		return topped ? `${separator}${localize('StatusLine.toppedAdvert')}${separatorBehind}` : ''
	}

	const getHelpdeskActionProps = (mailToSupport, mailSubject) => ({
		href: mailToSupport
			? `mailto:podpora@sauto.cz?subject=${encodeURI(
					mailSubject ? mailSubject : localize('StatusLine.contactHelpSubject', { ID: advertId })
			  )}`
			: 'https://o-seznam.cz/napoveda/sauto/dulezite-pro-vsechny/blokace-sluzby/',
		children: localize('StatusLine.contactHelp')
	})

	const getStatusLineText = () => {
		if (isPremise) {
			return localize('StatusLine.active') + getPreferredText(', ') + getToppedText(' - ')
		} else {
			return getToppedText('', ' - ') + localize('StatusLine.active')
		}
	}

	const statusData = {
		[ADVERT_STATE.ACTIVE]: {
			style: 'positive',
			text: (
				<>
					<strong>{getStatusLineText()}</strong>
					{!isPremise && localize('StatusLine.tillDate', { DATE: Format.date(validToDate) })}
				</>
			),
			actionProps: isPremise
				? undefined
				: {
						href: router.link(ROUTE_NAMES.CLIENT_ADMIN.NEW_ADVERT_PAYMENT, {
							[URL_APP_PARAMS.ADVERT_ID]: advertId
						}),
						children: localize('StatusLine.prolong')
				  }
		},
		[ADVERT_STATE.INACTIVE]: {
			style: 'neutral',
			text: localize('StatusLine.inactive') + getPreferredText(', ')
		},
		[ADVERT_STATE.INACTIVE_EXPIRED]: {
			style: 'neutral',
			text: (
				<>
					<strong>{localize('StatusLine.inactiveExpired') + getPreferredText(', ')}</strong>
					{!isPremise && localize('StatusLine.fromDate', { DATE: Format.date(validToDate) })}
				</>
			),
			actionProps: isPremise
				? undefined
				: {
						href: router.link(ROUTE_NAMES.CLIENT_ADMIN.NEW_ADVERT_PAYMENT, {
							[URL_APP_PARAMS.ADVERT_ID]: advertId
						}),
						children: localize('StatusLine.payAdvert')
				  }
		},
		[ADVERT_STATE.INACTIVE_INSUFFICIENT_IMAGES]: {
			style: 'neutral',
			text: localize('StatusLine.inactiveInsufficientImages') + getPreferredText(', ')
		},
		[ADVERT_STATE.INACTIVE_VIN_DUPLICATION]: {
			style: 'neutral',
			text: localize('StatusLine.innactiveVinDuplication') + getPreferredText(', '),
			actionProps: getHelpdeskActionProps(true)
		},
		[ADVERT_STATE.INACTIVE_VIN_DUPLICATION_FRAUD]: {
			style: 'neutral',
			text: localize('StatusLine.innactiveVinDuplicationFraud') + getPreferredText(', '),
			actionProps: getHelpdeskActionProps(
				true,
				localize('StatusLine.contactHelpVinDuplicationFraudSubject', { ID: advertId })
			)
		},
		[ADVERT_STATE.INACTIVE_NOT_PAID]: {
			style: 'neutral',
			text: localize('StatusLine.inactiveNotPaid') + getPreferredText(', ')
		},
		[ADVERT_STATE.INACTIVE_INSUFFICIENT_MODULES]: {
			style: 'neutral',
			text: localize('StatusLine.inactiveInsufficientModules') + getPreferredText(', ')
		},
		[ADVERT_STATE.INACTIVE_USER_DEACTIVATED]: {
			style: 'neutral',
			text: localize('StatusLine.inactiveUserDeactivated') + getPreferredText(', '),
			actionProps: isPremise
				? undefined
				: {
						onClick: () => {
							fire(ACTION_TYPE.PUBLISH, advertId)
						},
						children: localize('StatusLine.publish')
				  }
		},
		[ADVERT_STATE.INACTIVE_ADMIN_DEACTIVATED]: {
			style: 'neutral',
			text: (
				<>
					<strong>{localize('StatusLine.inactiveAdminDeactivated')}</strong>
					<span>{getPreferredText(', ')}</span>
				</>
			)
		},
		[ADVERT_STATE.INACTIVE_ADMIN_DEACTIVATED_REJECTED]: {
			style: 'neutral',
			text: (
				<>
					<strong>{localize('StatusLine.inactiveAdminDeactivatedRejected')}</strong>
					<span>{getPreferredText(' ')}</span>
				</>
			)
		},
		[ADVERT_STATE.DISABLED]: {
			style: 'negative',
			text: (
				<>
					<strong>{localize('StatusLine.disabled')}</strong>
					<span>{getPreferredText(', ')}</span>
				</>
			),
			actionProps: getHelpdeskActionProps(isPremise)
		},
		[ADVERT_STATE.DISABLED_REJECTED]: {
			style: 'negative',
			text: (
				<>
					<strong>{localize('StatusLine.disabledRejected')}</strong>
					<span>{getPreferredText(' ')}</span>
				</>
			),
			actionProps: getHelpdeskActionProps(isPremise)
		}
	}

	const { style, text, actionProps } = statusData[advertState] || {}

	return (
		<div
			className={classnames({
				[CLASSNAME]: true,
				[`${CLASSNAME}--${style}`]: !!style
			})}
		>
			{text}
			{typeof actionProps !== 'undefined' && (
				<>
					<span> – </span>
					<ActionElm tagName='button' className={`${CLASSNAME}__action-btn`} {...actionProps} />
				</>
			)}
		</div>
	)
}

StatusLineView.propTypes = {
	advertEntity: PropTypes.shape(advertPropTypes),
	advertState: PropTypes.string,
	isPremise: PropTypes.bool
}

export default StatusLineView
