import React from 'react'
import advertPropTypes from 'app/model/advert/AdvertPropTypes'
import PropTypes from 'prop-types'
import { useLocalize, useLink } from 'app/base/componentHelpers'
import { SDN } from 'app/base/Constants'
import UserwebAdvertView from 'app/component/adverts/UserwebAdvertView'
import { CATEGORIES as BASE_CATEGORIES } from 'app/base/Constants'
import SbrInfo from 'app/component/sbrInfo/SbrInfo'

import './CheckConditionLink.less'
import './CheckConditionLinkCS.json'

const CLASSNAME = 'c-condition-check-link'

const KIND_ID = {
	[BASE_CATEGORIES.PASSENGER_CARS.id]: 1,
	[BASE_CATEGORIES.UTILITY_CARS.id]: 4,
	[BASE_CATEGORIES.TRUCKS.id]: 5,
	[BASE_CATEGORIES.MOTORCYCLE.id]: 3,
	[BASE_CATEGORIES.QUAD.id]: 11,
	[BASE_CATEGORIES.TRAILERS.id]: 7,
	[BASE_CATEGORIES.CAMPER.id]: 9,
	[BASE_CATEGORIES.WORKING_MACHINES.id]: 10,
	[BASE_CATEGORIES.BUSES.id]: 6
}

const CheckConditionLink = ({ advertEntity, isOperatingLease }) => {
	const localize = useLocalize()
	const link = useLink()

	const { category, images, locality, manufacturerCb, modelCb, premise } = advertEntity

	const allowCategoryIds = [
		BASE_CATEGORIES.PASSENGER_CARS.id,
		BASE_CATEGORIES.UTILITY_CARS.id,
		BASE_CATEGORIES.MOTORCYCLE.id,
		BASE_CATEGORIES.CAMPER.id
	]

	if (allowCategoryIds.includes(category?.id)) {
		const text =
			category?.id === BASE_CATEGORIES.MOTORCYCLE.id
				? localize('CheckConditionLink.moto')
				: localize('CheckConditionLink.car')

		const advertUrl = link(...UserwebAdvertView.getAdvertUrlParams(advertEntity, isOperatingLease))

		const imageUrl = images?.[0]?.url

		const utmParams = {
			utm_source: 'sauto',
			utm_medium: 'affil',
			utm_campaign: 'sauto_autobezobav',
			utm_content: 'button',
			url: advertUrl,
			image: imageUrl ? `${imageUrl.startsWith('//') ? 'https:' : ''}${imageUrl}${SDN.w360}` : '',
			name: manufacturerCb?.name || '',
			subname: modelCb?.name || '',
			region: locality?.districtId || '',
			city:
				locality?.municipality ||
				premise?.locality?.municipality ||
				locality?.district ||
				premise?.locality?.district ||
				locality?.region ||
				premise?.locality?.region ||
				'',
			prodejce: premise ? premise.name?.replace(/\s+/g, ' ') || '' : 'Soukromý prodejce',
			service: KIND_ID[category?.id] || ''
		}

		const urlUtm = Object.keys(utmParams)
			.map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(utmParams[key]))
			.join('&')

		const url = `https://www.autobezobav.cz/sauto/?${urlUtm}`

		return (
			<>
				<a
					className={CLASSNAME}
					href={url}
					target='_blank'
					rel='nofollow noopener noreferrer'
					data-dot='check-condition-link'
				>
					{text}
				</a>
				<SbrInfo paid='AUTO BEZ OBAV, s.r.o.' behalf='AUTO BEZ OBAV, s.r.o.' />
			</>
		)
	}

	return null
}

CheckConditionLink.propTypes = {
	advertEntity: PropTypes.shape(advertPropTypes),
	isOperatingLease: PropTypes.bool
}

export default React.memo(CheckConditionLink)
