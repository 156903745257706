
		(function (root) {
			var $IMA = {};
			root.$IMA = root.$IMA || {};
			$IMA = root.$IMA;
			$IMA.i18n = $IMA.i18n || {};
			
$IMA.i18n['CarInsurance'] = {
  header: function(d) { return "Povinné ručení"; },
  range: function(d) { return "Povinné ručení pro tento vůz vychází v rozmezí:"; },
  moreInfo: function(d) { return "Zjistit více o možnostech pojištění"; },
  moreInfoMobile: function(d) { return "Zjistit více o možnostech"; }
};
			return $IMA.i18n;
		})(typeof window !== 'undefined' && window !== null ? window : global);
	