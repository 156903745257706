import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { useFire } from 'app/base/componentHelpers'
import { useInView } from 'react-intersection-observer'

export function FirstViewLazyFire(props) {
	const { children, advertId, isOperatingLease = false, fireEvent, fireEventParams = {} } = props
	const alreadyFiredForParams = useRef()
	const fire = useFire()
	const { ref, inView } = useInView({
		rootMargin: '500px'
	})

	useEffect(() => {
		if (
			advertId &&
			(advertId !== alreadyFiredForParams.current?.advertId ||
				isOperatingLease !== alreadyFiredForParams.current?.isOperatingLease) &&
			inView
		) {
			alreadyFiredForParams.current = { advertId, isOperatingLease }
			fire(fireEvent, fireEventParams)
		}
	}, [advertId, isOperatingLease, inView, fire, fireEvent, fireEventParams])

	return <div ref={ref}>{children}</div>
}

FirstViewLazyFire.propTypes = {
	children: PropTypes.node,
	advertId: PropTypes.number,
	isOperatingLease: PropTypes.bool,
	fireEvent: PropTypes.string,
	fireEventParams: PropTypes.object
}
